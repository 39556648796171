.health-check-title {
  font-size: 40px !important;
  font-weight: 700 !important;
}
.health-check-title-data {
  font-size: 36px !important;
  font-weight: 700 !important;
}
.health-check-p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.package-test-box {
  padding: 25px 20px;
  background-color: #e6f6fe;
  border-radius: 12px;
  overflow: auto;
  scrollbar-width: thin;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.package-test-box h6 {
  color: #01abeb;
  font-size: 24px;
  font-weight: 700;
}
.package-test p {
  font-size: 16px !important;
  font-weight: 400;
  margin-bottom: 9px !important;
}
.book-health-popup {
  width: 890px;
  margin-left: auto;
  margin-right: auto;
}

.package-details-box {
  padding: 25px 20px;
  background-color: #edf0ff;
  border-radius: 12px;
  overflow: auto;
  scrollbar-width: thin;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.package-details-box h6 {
  color: #354895;
  font-size: 24px;
  font-weight: 700;
}
.package-details-box h5 {
  color: #333333;
  font-size: 20px;
  font-weight: 700;
}
.pack-detail-head {
  font-size: 16px;
  font-weight: 700;
}
.package-details-box p {
  font-size: 16px !important;
  font-weight: 400;
  margin-bottom: 9px !important;
}
.book-health-check {
  background-color: #354895;
  color: #ffff;
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
}
.health-detail-box {
  background-color: #00aff0;
  height: 231px;
  width: 100%;
  border-radius: 0px 14px 14px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffff;
}
.health-check-btn {
  background-color: #354895;
  color: #fff;
  padding: 10px 20px;
  border: none;
  font-size: 14px;
  border-radius: 6px;
}

@media (max-width: 1199px) {
  .book-health-popup {
    width: 100%;
  }
  .healthcheck-data-image {
    border-radius: 0px;
    width: 100%;
    height: 315px;
  }

  .healthcheck-sec {
    flex-direction: column;
    align-items: center;
    border-radius: 0 ;
  }
  .filter-drop {
    text-align: justify !important;
  }
}
@media (max-width: 950px) {
  .pack-type-box {
    flex-direction: column;
    align-items: center;
  }
  .healthcheck-sec {
    flex-direction: column;
    align-items: center;
    border-radius: 0 !important;
  }
  .health-detail-box {
    border-radius: 14px;
  }
  .health-detail-img {
    border-radius: 14px;
  }
  .healthcheck-data-image {
    border-radius: 0px;
    width: 100%;
    height: 315px;
  }

}
.health-detail-img{
  border: 1px solid rgb(0, 175, 240);
  border-radius: 12px 0px 0 12px;
}
@media (max-width: 768px) {
  .pack-type-box {
    flex-direction: column;
    align-items: center;
  }
  .health-detail-box {
    padding: 20px 10px;
    margin-top: 15px;
  }
  .healthcheck-data-image {
    border-radius: 0px;
    width: 100%;
    height: 315px;
  }
  .healthcheck-sec {
    flex-direction: column;
    align-items: center;
  }
  .health-check-title {
    font-size: 29px !important;
  }
  .health-check-title-data {
    font-size: 29px !important;
  }
  .package-test p {
    font-size: 16px !important;
  }
  .package-details-box p {
    font-size: 16px !important;
  }
  .contact-form-title {
    font-size: 24px !important;
  }
  .package-details-box h5 {
    font-size: 18px;
  }
  .package-details-box h6 {
    font-size: 24px;
  }
  .package-test-box h6 {
    font-size: 24px;
  }
  .pack-detail-head {
    margin-bottom: 10px;
  }
  .book-health {
    font-size: 16px;
  }
  .book-health-check {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .health-check-p {
    font-size: 16px;
  }

}

@media (max-width: 480px) {
  .health-detail-box {
    padding: 15px 10px;
  }
  .health-check-title {
    font-size: 28px !important;
  }
  .health-check-title-data {
    font-size: 28px !important;
  }
  .package-test p {
    font-size: 14px !important;
  }
  .package-details-box p {
    font-size: 14px !important;
  }
  .contact-form-title {
    font-size: 20px !important;
  }
  .package-details-box h5 {
    font-size: 16px;
  }
  .package-details-box h6 {
    font-size: 22px;
  }
  .package-test-box h6 {
    font-size: 22px;
  }
  .pack-detail-head {
    margin-bottom: 10px;
  }
  .book-health {
    font-size: 14px;
  }
  .book-health-check {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .health-check-p {
    font-size: 14px;
  }
  .healthcheck-data-image {
    border-radius: 0px;
    width: 100%;
    height: 249px !important;
  }
  .femina-health-check-data {

    padding: 10px;
  }
  .filter-drop-data-1{
    height: 46px;
  }
  label#demo-simple-select-label\ filter-drop-data {
    font-size: 14px;
  }
 
}
/* @media (max-width: 1536px) {
  .healthcheck-data-image {
    height: 315px;
  }
} */

@media (max-width: 400px) {
  .contact-form-title {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
}
@media (min-width: 576px) and (max-width: 1024px) {
  .healthcheck-data-image {
    height: 249px;
  }
  .femina-health-check-data {
    padding: 10px;
  }
  .commit-sec {
    padding: 22px 83px;
  }
  .box-postions {
    padding: 10px 83px;
  }
  .about-sec{
    padding: 22px 83px;
  }
  .out-commit-sec{
    padding: 50px 83px;
  }
  .standard-car{
    padding: 50px 83px;
  }
  .specialtiessa-container{
    padding: 50px 83px;
  }
  .footer-wrapper{
    padding: 22px 83px;
  }
  .navbar{
    padding: 5px 83px;
  }
  .contact-div{
    padding: 50px 83px;
  }
}
.react-datepicker-wrapper{
  width: 100%;
}