.speciality-label {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowed text */
    text-overflow: ellipsis; /* Add ellipsis */
    max-width: 200px; /* Set a maximum width for the text */
    display: inline-block; /* Ensure the ellipsis works */
  }

  @media (max-width: 768px) {
    /* .doct-pro {
      display: flex;
      flex-direction: column; 
      align-items: flex-start; 
    } */
  
    /* .speciality-label {
      max-width: 100%; 
      white-space: normal; 
      overflow: visible; 
    } */
  }

  @media (max-width: 768px) {
    .speciality-chip {
      white-space: normal; /* Allow wrapping */
      text-overflow: unset;
      overflow: visible;
    }
  }