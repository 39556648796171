body {
  margin: 0;
  font-family: "Albert Sans", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-ppq8gy-MuiButtonBase-root-MuiMenuItem-root {
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  user-select: none !important;
  vertical-align: middle !important;
  appearance: none !important;
  color: inherit;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
  display: flex !important;
  -webkit-box-pack: start;
  justify-content: flex-start !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  position: relative !important;
  text-decoration: none !important;
  min-height: 48px;
  padding: 6px 16px !important;
  box-sizing: border-box;
  white-space: nowrap !important;
}


.css-y67ick-MuiPagination-root .Mui-selected {
  border-color: var(--secondary-color) !important;
  background-color: var(--secondary-color) !important;
  color: var(--white) !important;
}

.css-y67ick-MuiPagination-root .MuiPaginationItem-root {
  border-color: var(--secondary-color) !important;
  border-width: 1px  !important;
  border-style: solid  !important;
  margin-top: 30px  !important;
  border-radius: 16px !important;
} 
.css-14rbfzw-MuiButtonBase-root-MuiPaginationItem-root{
  border-radius: 16px !important;
  margin: 0 3px !important;
}
.css-tgzcll {
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  user-select: none !important;
  vertical-align: middle !important;
  appearance: none !important;
  color: inherit;
  font-family: "Albert Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  display: flex !important;
  -webkit-box-pack: start;
  justify-content: flex-start !important;
  -webkit-box-align: center;
  align-items: center !important;
  position: relative !important;
  text-decoration: none !important;
  /* min-height: 48px !important; */
  padding: 6px 16px !important;
  box-sizing: border-box;
  white-space: nowrap !important;
}
.css-1d54uzw {
  border-radius: 16px !important;
  margin: 0 3px !important;
}



/* Global Webkit Scrollbar Style */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Background of the scrollbar track */
  border-radius: 8px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #909090; /* Thumb color on hover */
}

/* Optional: Scrollbar style for specific elements */
.scroll-container {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #b0b0b0 #f0f0f0; /* For Firefox */
}
