.general-btn {
  background-color: #00aff0 !important;
  border: none !important;
  width: 75px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px !important;
  color: #ffff !important;
  text-transform: unset !important;
}
.view-more-btn {
  padding: 9px 14px;
  border-radius: 8px;
  background: white;
  border: 1px solid #354895;
  box-shadow: 0px 8px 16px 0px #82828229;
  color: #354895;
  font-size: 13px;
}

.book-appo-btn {
  padding: 9px 14px;
  border-radius: 8px;
  background: #354895;
  border: 1px solid #354895;
  box-shadow: 0px 8px 16px 0px #82828229;
  color: white;
  font-size: 14px;
}
.femina-health-check-data {
  width: 100%;
  padding: 20px;
}
.healthcheck-data-image {
  border-radius: 12px 0px 0px 12px;
  /* height: 294px; */
}
.healthcheck-sec{
  border: 1px solid rgb(53, 72, 149);
  border-radius: 12px;
}
@media (max-width: 768px) {
  .health-check-img {
    width: 100%;
  }
}
