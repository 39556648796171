img.second-image {
  width: 100%;
  height: 100%;
  position: relative;
}

img.second-image11 {
  /* position: absolute; */
  top: 30%;
  width: 548px;
}
.header-image {
  display: flex;
  position: relative;
}

.relative-image-container {
  position: absolute;
  top: 125px;
}
.image-text-about {
  position: absolute;
  top: 21%;
  padding: 0px 0px 0px 128px;
}

.card-asp-title {
  padding: 20px 48px;
  background: #01adec;
  color: white !important;
  font-weight: 700;
  border-radius: 16px 16px 0px 0px !important;
}

/* .absolute-image-container {
  margin-left: 398px;
} */
.absolute-image {
  width: 100%;
}
.about-sec {
  padding: 76px 140px 23px;
}
h1.text-about {
  font-size: 50px; 
  font-weight: 900;
  color: #06b0f0;
  display: block; /* Ensures proper box rendering */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
  display: -webkit-box; /* Enable multi-line ellipsis */
  -webkit-box-orient: vertical; /* Vertical orientation for the box */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  line-height: 1.2; /* Adjust the line height */
  max-width: 724px; /* Adjust to your container width */
  word-wrap: break-word; /* Allow long words to break */
  white-space: normal; /* Allow text wrapping */
}
.specialities-title-p {
  display: block; /* Ensures proper box rendering */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
  display: -webkit-box; /* Enable multi-line ellipsis */
  -webkit-box-orient: vertical; /* Vertical orientation for the box */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  line-height: 1.2; /* Adjust the line height */
  max-width: 600px; /* Adjust to your container width */
  word-wrap: break-word; /* Allow long words to break */
  white-space: normal; /* Allow text wrapping */
}
p.text-health {
  font-size: 24px;
  color: white;
  margin-top: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Main Section Flex Layout */
.main-section-about {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  gap: 80px; /* Adds space between items */
  margin-top: 20px;
}

/* About Text Section */
.Experience-about-1 {
  font-size: 36px;
  font-weight: 700;
}
.global-about {
  font-size: 16px;
  font-weight: 400;
  color: black;
  line-height: 26px;
}
.commitment-sec {
  background-color: #e6f6fe;
}
.patients-box {
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 28px 25px;
  height: 257px;
}
.out-commit-sec {
  padding: 113px 128px;
}
.out-commit-sec p {
  line-height: 29px;
  font-size: 16px;
  text-align: start;
}
/* About Images Section */
.about-images {
  display: flex;
  flex-direction: column; /* Stacks images vertically */
  justify-content: center;
  align-items: center; /* Centers images horizontally */
  position: relative; /* Relative position for containing child elements */
  gap: 80px; /* Adds space between stacked images */
  width: 100%;
}

.builder-image,
.doctor-image {
  width: 100%; /* Images take full container width */
  max-width: 450px; /* Limits maximum size */
  height: auto; /* Maintains aspect ratio */
}

.doctor-image {
  position: absolute;
  width: 100%;
  max-width: 500px;
  height: 500px;
  top: 48%;
  left: 45%;
  transform: translate(-50%, -50%);
}
.specialties-card2 {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  border-radius: 10px !important;
  height: 326px !important;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .about-images {
    gap: 20px; /* Reduce gap for smaller screens */
  }
  .Experience-about-1 {
    font-size: 24px;
    font-weight: 700;
  }

  .doctor-image {
    position: relative; /* Remove absolute positioning for stacking */
    transform: none;
    height: auto;
  }
  .out-commit-sec {
    padding: 26px;
  }
}

.vison-h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}
.main-aspirations {
  display: flex;
  gap: 31px;
}
.commit-sec {
  padding: 22px 128px;
}
.aspirations-text {
  /* padding: 22px; */
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-bottom: 16px;
  /* width: 588px; */
  height: 420px;
  overflow: auto;
  scrollbar-width: thin;
}

.aspirations-text h2 {
  font-size: 25px;
  font-weight: bold;
  color: #354895; /* Vision-themed color */
  margin-bottom: 16px;
  text-transform: uppercase;
}

.vision-about {
  font-size: 16px;
  line-height: 1.6;
  color: #333333; /* Neutral text color */
  margin-bottom: 16px;
  padding: 0px 48px;
}
.main-imges {
  display: flex;
  flex-wrap: wrap; /* Allows responsive wrapping */
  justify-content: center; /* Centers columns */
  gap: 20px; /* Space between the columns */
  margin-top: 30px; /* Add space above the grid */
}

/* Column styling */
.about-vision {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1; /* Allows flex to expand equally */
  width: 100%; /* Ensures it takes full width up to max */
  height: 196px;
}
.hospital-about-img {
  width: 500px;
}
/* Box styling */
.mask-about {
  display: flex;
  flex-direction: row; /* Stacks image and text */
  background-color: #354895;
  color: white;
  border-radius: 16px;
  width: 100%;
  padding: 20px;

  justify-content: center;
  align-items: center;
  height: 100%;
}

.mask-about img {
  width: 118px;
  height: 118px;
  /* margin-bottom: 20px; */
  margin-right: 23px;
}

/* Text styling */
.vison-about-1 h2 {
  margin-bottom: 15px;
  font-size: 24px;
}

.vison-about-1 p {
  font-size: 16px;
  line-height: 1.5;
  color: white;
}
.dummy-text {
  font-weight: 300;
  letter-spacing: 1px;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .mask-about {
    padding: 15px;
  }

  .commit-sec {
    padding: 22px 26px;
    margin-top: 0 !important;
  }
  .about-sec {
    padding: 34px 26px 22px 26px;
  }
  .standard-car {
    padding: 26px !important;
  }
  .vison-about-1 h2 {
    font-size: 20px;
    margin-bottom: 6px;
  }
  .hospital-about-img {
    width: 282px;
  }
  .female-doctor {
    display: none;
  }

  .vison-about-1 p {
    font-size: 14px;
  }
  .vison-h2 {
    font-size: 27px;
    text-align: start !important;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.specialty-card2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  max-width: 100%;
  height: 100%; /* Ensure the card takes full height of the container */
}

.special-image-about {
  width: 100%;
  height: 217px; /* Fixed height for image */
  object-fit: cover; /* Ensure image covers the area */
}

.specialty-heading {
  margin-bottom: 8px;
  font-size: 1.25rem;
}

.MuiCardContent-root {
  flex: 1; /* Ensure content area takes available space */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-about-catogory {
  text-align: center;
  display: block;
  margin: auto;
}
.specialtiessa-container h3 {
  font-size: 36px;
  font-weight: 700;
}
.specialtiessa-container {
  background-color: #e6f6fe;
  position: relative;
  padding: 83px 128px;
}
.special-image-surgery {
  width: 49.24px;
  height: 51px;
  margin-bottom: 20px;
}
img.waves {
  position: absolute;
  top: 3%;
  z-index: 0;
  width: 99%;
  height: 314px;
  text-align: center;
}
.standard-car {
  padding: 88px 128px;
}
.Review-car {
  background-color: #354895;
  color: white;
  position: relative;
  margin-top: 132px;
}
.review-mark {
  background-color: #00aff0;
  padding: 40px;
  border-radius: 20px;
}
.review-h2 {
  text-align: -webkit-center;
  color: #003c53;
  font-size: 22px;
  margin-left: 28px;
  margin-right: 45px;
}
.css-zmci4r-MuiTypography-root-MuiBreadcrumbs-root {
  line-height: 1rem !important;
}
.css-fhj57y {
  line-height: 1rem !important;
}
/* @media(max-width:1399.98px){
  .doctor-image{
    height: auto;
    position: relative;
  }
} */
@media (max-width: 1200px) {
  .hospital-about-img {
    width: 318px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .aspirations-text {
    height: auto;
    padding-bottom: 16px;
  }
  .female-doctor {
    position: absolute;
    top: -17%;
    width: 415px;
  }
  .female-doctor {
    display: none;
  }
  .patient-box-p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 200px; /* Optional: Adjust as needed */
  }
  .patients-box {
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 12px 13px;
    height: 226px;
  }
}
@media (max-width: 375px) {
  .image-text-about {
    position: absolute;
    top: 33% !important;
  }
}
@media (max-width: 360px) {
  .image-text-about {
    position: absolute;
    top: 34% !important;
  }
}
@media (max-width: 320px) {
  .image-text-about {
    position: absolute;
    top: 30% !important;
  }
  .hospital-about-img {
    width: 243px !important;
  }
}

@media (min-width: 1300px) and (max-width: 1536px) {
  .aspirations-text {
    height: 576px;
  }
  .patients-box {
    height: 296px;
  }
  .female-doctor {
    position: absolute;
    top: -17%;
    width: 500px;
  }
  .Review-car {
    margin-top: 220px;
  }
  .image-text-about {
    position: absolute;
    top: 25%;
  }
  .breadcrumb {
    margin-top: 0px !important;
  }
}
@media (min-width: 900px) and (max-width: 1300px) {
  .patients-box {
    height: auto;
  }
  .female-doctor {
    position: absolute;
    top: -23%;
    width: 437px;
  }
}
@media (min-width: 200px) and (max-width: 480px) {
  p.text-health {
    font-size: 9px;
    color: white;
    margin-top: -5px;
    margin-bottom: 0 !important;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 176px;
  }
  .specialities-title-p {
    display: block; /* Ensures proper box rendering */
    overflow: hidden; /* Hides the overflowing text */
    text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
    display: -webkit-box; /* Enable multi-line ellipsis */
    -webkit-box-orient: vertical; /* Vertical orientation for the box */
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    line-height: 1.2; /* Adjust the line height */
    width: 60%; /* Adjust to your container width */
    word-wrap: break-word; /* Allow long words to break */
    white-space: normal; /* Allow text wrapping */
  }
  h1.text-about {
    font-size: 12px;
    font-weight: 900;
    color: #06b0f0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    width: 70%;
  }
  .image-text-about {
    position: absolute;
    top: 14%;
    padding: 0px 0px 0px 10px;
  }
  .breadcrumb {
    display: none !important;
  }
  .vision-about {
    padding: 0px 12px !important;
  }
  .aspirations-text h2 {
    font-size: 20px;
  }
  .card-asp-title {
    padding: 20px 11px !important;
  }
  .mask-about img {
    width: 65px;
    height: 91px;
    object-fit: contain;
    /* margin-bottom: 20px; */
    margin-right: 23px;
  }
  .vision-1p {
    text-align: left !important;
  }
  .Review-car {
    margin-top: 0 !important;
  }
  .specialties-box-about {
    margin-bottom: 20px !important;
  }
  .specialtiessa-container {
    background-color: #e6f6fe;
    position: relative;
    padding: 46px 26px !important;
  }
  .specialtiessa-container h3 {
    font-size: 30px !important;
  }
  .standard-car h2 {
    font-size: 22px !important;
    line-height: 32px !important;
  }
  .dummy-text {
    font-size: 14px !important;
  }
  .review-mark {
    margin-top: 0 !important;
  }
  .about-title {
    margin-top: 18px !important;
  }
  .global-about {
    margin-top: 20px !important;
  }
  .review-h2 {
    text-align: -webkit-center;
    color: #003c53;
    font-size: 18px;
    margin-left: 28px;
    margin-right: 45px;
  }

  .doct-pro {
    width: 200px !important;
  }
  .doctor-title {
    font-size: 32px !important;
  }
  .doct-pro-sec {
    justify-content: center !important;
  }
  /*.doct-pro-sec .MuiBox-root.css-1i27l4i{
  justify-content: center !important;
} */
}
@media (min-width: 481px) and (max-width: 580px) {
  h1.text-about {
    font-size: 16px;
    font-weight: 900;
    color: #06b0f0;
    margin-top: 5px;
    line-height: 1.2;
    width: 60%;
  }
  p.text-health {
    font-size: 10px;
    margin-top: -5px;
    margin-bottom: 22px;
    /* white-space: nowrap; */
  }

  .breadcrumb {
    /* border: 2px solid; */
    /* width: 194px; */
    display: block;
    border-radius: 47px;
    padding: 0px !important;
    padding-inline: 10px !important;
    width: 115px !important;
    color: white;
    background-color: #4f67cb69;
    margin-top: 0px !important;
    text-decoration: none;
  }
  .image-text-about {
    position: absolute;
    top: 10%;
    padding: 0px 0px 0px 10px;
  }
  .vision-about {
    padding: 0px 12px !important;
  }
  .aspirations-text h2 {
    font-size: 20px;
  }
  .card-asp-title {
    padding: 20px 11px !important;
  }
  .mask-about img {
    width: 65px;
    height: 91px;
    object-fit: contain;
    /* margin-bottom: 20px; */
    margin-right: 23px;
  }
  .vision-1p {
    text-align: left !important;
  }
  .Review-car {
    margin-top: 0 !important;
  }
  .specialties-box-about {
    margin-bottom: 20px !important;
  }
  .specialtiessa-container {
    background-color: #e6f6fe;
    position: relative;
    padding: 46px 26px !important;
  }
  .specialtiessa-container h3 {
    font-size: 30px !important;
  }
}
@media (min-width: 581px) and (max-width: 768px) {
  h1.text-about {
    font-size: 18px;
    font-weight: 900;
    width: 77%;
  }
  p.text-health {
    font-size: 12px;
    margin-top: 0px;
  }
  .image-text-about {
    position: absolute;
    top: 10%;
    padding: 0px 0px 0px 10px;
  }

  .breadcrumb {
    /* border: 2px solid; */
    width: 144px !important;
    display: block;
    border-radius: 47px;
    padding: 4px !important;
    padding-inline: 14px !important;
    color: white;
    background-color: #4f67cb69;
    margin-top: 0px !important;
    text-decoration: none;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  h1.text-about {
    font-size: 20px;
    font-weight: 900;
    width: 77%;
  }
  .specialities-title-p {
    width: 80%; /* Adjust to your container width */
  }
  p.text-health {
    font-size: 14px;
  }
  .image-text-about {
    position: absolute;
    top: 8%;
    padding: 0px 0px 0px 83px;
  }
  .breadcrumb {
    /* border: 2px solid; */
    width: 144px !important;
    display: block;
    border-radius: 47px;
    padding: 4px !important;
    padding-inline: 18px !important;
    color: white;
    background-color: #4f67cb69;
    margin-top: 0px !important;
    text-decoration: none;
  }
}
@media (min-width: 1025px) and (max-width: 1299.98px) {
  h1.text-about {
    font-size: 26px;
    font-weight: 900;
    width: 90%;
  }
  .specialities-title-p {
    width: 80%; /* Adjust to your container width */
  }
  p.text-health {
    font-size: 20px;
    margin-top: 0px;
  }
  .image-text-about {
    position: absolute;
    top: 18%;
  }
  .breadcrumb {
    /* border: 2px solid; */
    width: 144px !important;
    display: block;
    border-radius: 47px;
    padding: 4px !important;
    padding-inline: 14px !important;
    color: white;
    background-color: #4f67cb69;
    margin-top: 0px !important;
    text-decoration: none;
  }
}
@media (min-width: 1300px) and (max-width: 1536px) {
  p.text-health {
    font-size: 24px;
    color: white;
    margin-top: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 403px;
  }
  h1.text-about {
    font-size: 32px;
    font-weight: 900;
    color: #06b0f0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    width: 90%;
  }
}
@media (max-width: 576px) {
  .absolute-image {
    height: 110px;
  }
}
@media (min-width: 480px) and (max-width: 580px) {
  .specialities-title-p {
    display: block; /* Ensures proper box rendering */
    overflow: hidden; /* Hides the overflowing text */
    text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
    line-height: 1.2; /* Adjust the line height */
    width: 60%; /* Adjust to your container width */    
    word-wrap: break-word; /* Allow long words to break */
    white-space: nowrap; /* Allow text wrapping */
  }
}
@media (min-width: 581px) and (max-width: 670px) {
  .specialities-title-p {
    display: block; /* Ensures proper box rendering */
    overflow: hidden; /* Hides the overflowing text */
    text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
    line-height: 1.2; /* Adjust the line height */
    width: 70%; /* Adjust to your container width */
    word-wrap: break-word; /* Allow long words to break */
    white-space: nowrap; /* Allow text wrapping */
  }
}
@media (min-width: 670px) and (max-width: 768px){
  .specialities-title-p {
    width: 80%; /* Adjust to your container width */
  }
}
